import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';

import logo from './logo.svg';
import './App.css';

ReactGA.initialize('UA-126406946-3', {
  gaAddress: 'https://kaikov.heterodox.io/js/app.js',
});

ReactGA.pageview(window.location.pathname + window.location.search);

const Logo = () => (
  <img src={logo} height={'200px'} alt="logo" style={{ padding: '2rem' }} />
);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Logo />
        <Typography variant="h2" style={{ fontFamily: 'Averia Serif Libre' }}>
          Heterodox
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontFamily: 'Averia Serif Libre',
            minWidth: '300px',
            maxWidth: '600px',
            margin: '2rem auto',
          }}
        >
          We're a team of competent makers, engineers, lecturers and
          graphic designers, working on challenging projects.
        </Typography>
        <a
          style={{
            textDecoration: 'underline',
            textDecorationColor: 'rgba(0,0,0,0.87)',
          }}
          href="https://goo.gl/forms/vn2uUomyxBQ8owGn2"
        >
          <Button
            style={{ fontFamily: 'Averia Serif Libre', fontSize: '1.5rem' }}
          >
            Enquire
          </Button>
        </a>
      </div>
    );
  }
}

export default App;
